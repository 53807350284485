// const initialState = {
//   details: null,
//   status: "idle",
//   error: null,
//   web_details: null,
//   web_status: "idle",
//   error1: null,
// };
const initialCompanyState = {
  details: null,
  status: "idle",
  error: null,
};

const initialWebsiteState = {
  web_details: null,
  web_status: "idle",
  error1: null,
};

export const CompanyReducer = (state = initialCompanyState, action) => {
  console.log("wb1188", action);
  switch (action.type) {
    case "FETCH_COMPANY_DETAILS_REQUEST":
      return {
        ...state,
        status: "loading",
      };
    case "FETCH_COMPANY_DETAILS_SUCCESS":
      return {
        ...state,
        status: "succeeded",
        details: action.payload,
      };
    case "FETCH_COMPANY_DETAILS_FAILURE":
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    default:
      return state;
  }
};

export const WebsiteReducer = (state = initialWebsiteState, action) => {
  console.log("wb11889", action);
  switch (action.type) {
    case "FETCH_WEBSITE_DETAILS_REQUEST":
      return {
        ...state,
        web_status: "loading",
      };
    case "FETCH_WEBSITE_DETAILS_SUCCESS":
      return {
        ...state,
        web_status: "succeeded",
        web_details: action.payload,
      };
    case "FETCH_WEBSITE_DETAILS_FAILURE":
      return {
        ...state,
        web_status: "failed",
        error1: action.payload,
      };
    default:
      return state;
  }
};
