function loadData(key) {
  try {
    let data = sessionStorage.getItem(key);
    data = JSON.parse(data);
    console.log(data)
    return data;
  } catch (err) {
    return undefined;
  }
}

function saveData(key, data) {
  sessionStorage.setItem(key, JSON.stringify(data));
}

export { loadData, saveData };
