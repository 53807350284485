import { loadData, saveData } from "../../utils/localStorage";
// import { LOGIN_FAILURE, LOGIN_SUCCESS } from "./actionTypes";

const token = loadData("token");

const iniState = {
  isAuth: token ? true : false,
  token: token || "",
  details: null,
};

export const LoginReducer = (state = iniState, { type, payload }) => {
  console.log(payload, "payload12");
  switch (type) {
    case "LOGIN_SUCCESS":
      saveData("token", payload.access);
      return {
        ...state,
        isAuth: true,
        token: payload.access,
        details: payload.user,
      };
    case "LOGIN_FAILURE":
      saveData("token", "");
      return {
        ...state,
        isAuth: false,
        token: "",
        details: null,
      };
    default:
      return state;
  }
};
