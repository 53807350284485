import Image from "next/image";
import Link from "next/link";

import AboutData from "../../data/elements/about.json";
import image from "../../public/images/others/login_image.svg";
import BreadCrumb from "../Common/BreadCrumb";
import styles from "@/styles/Login.module.css";
import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { loginSuccess, loginFailure } from "../../redux/action/LoginAction";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { saveData } from "@/utils/localStorage";
import styles1 from "@/styles/Swal.module.css";
// import "../../public/css/style.css";
// public / scss / styles.scss;
import Swal from "sweetalert2";
import Select from "react-select";
// import { Select } from "@mui/material";

const stylesDD = {
  container: (provided) => ({
    ...provided,
    height: "40px",
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: state.isFocused ? "1px solid #59dc42" : "1px solid #ccc", // Green border when focused
    "&:hover": {
      border: "1px solid #59dc42", // Green border on hover
    },
    boxShadow: "none", // Remove default shadow
    "&:hover": {
      border: "1px solid #59dc42", // Green border on hover
    },
    fontSize: "14px",
    height: "40px",
  }),
  placeholder: (provided) => ({
    ...provided,
    lineHeight: "40px",
    // height: "100%",
    // padding: "0px 8px",
    // marginTop: "-5px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "100%", // Adjust the height of the value container
    padding: "0px 8px", // Adjust padding as needed
    marginTop: "-10px",
    lineHeight: "40px",
  }),
  singleValue: (provided) => ({
    ...provided,
    lineHeight: "40px", // Align the text vertically
  }),
  option: (base) => ({
    ...base,
    // border: `1px dotted red`,
    height: "100%",
    fontSize: "14px",
  }),
};
const LoginPage = ({ handleClose }) => {
  const [education, Seteducation] = useState();
  const [reg, setReg] = useState(false);
  const { details } = useSelector((state) => state.CompanyReducer);
  const router = useRouter();
  const { orgid } = router.query;
  // const id = orgid;
  const id = orgid || "SMR-JP-758620";
  const dispatch = useDispatch();
  const [loginMode, setLoginMode] = useState("otp");
  const [creds, setCreds] = useState({
    username: "",
    password: "",
  });
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [screen, setScreen] = useState(1);

  const handleChange = (e) => {
    setCreds({ ...creds, [e.target.name]: e.target.value });
  };

  const ShowAlertTimer = ({ icon, title, timer }) => {
    Swal.fire({
      icon: icon,
      title: title,
      showConfirmButton: false,
      timer: timer,
    });
  };
  const handleChangeMobile = (e) => {
    const value = e.target.value;
    const regex = /^\d{0,10}$/;

    if (regex.test(value)) {
      setMobile(value);
      // setError("");
    } else {
      // setError("Please enter exactly 10 digits.");
    }
  };
  const showAlert = () => {
    Swal.fire({
      icon: "error",
      title: "Enter a valid email!",
      showConfirmButton: false,
      timer: 2000,
      customClass: {
        container: styles1.swalCustomZindex,
        popup: styles1.swalCustomZindex,
      },
    });
  };
  const handlePasswordSubmit = (e) => {
    e.preventDefault();

    if (creds.username === "") {
      showAlert();
    } else if (creds.password === "") {
      Swal.fire({
        icon: "error",
        title: "Enter a password!",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: styles1.swalCustomZindex,
          popup: styles1.swalCustomZindex,
        },
      });
    } else {
      axios
        .post(
          `${process.env.NEXT_PUBLIC_BACKEND_GENERIC_URL}/skilling/${id}/auth/login`,
          {
            email: creds.username,
            // mobnum: creds2.mobile,
            password: creds.password,
          }
        )
        .then((res) => {
          console.log(res.data, "response11");
          dispatch(loginSuccess(res.data));
          saveData("user", res.data.user);
          sessionStorage.setItem("candid", res.data.user.registration_id);
          setScreen(1);
          handleClose();
          Swal.fire({
            //position: "top",
            icon: "success",
            title: "Login successful!",
            // text: res.response.data["User Message"],
            showConfirmButton: true,
            timer: 3000,
            customClass: {
              popup: styles1.mypopup,
              title: styles1.mytitle,
              icon: styles1.myicon,
              container: styles1.swalCustomZindex,
              // popup: styles1.swalCustomZindex,
              confirmButton: styles1.button11,
            },
          }).then(() => {
            window.location.reload();
          });
          // router.push("/");
        })
        .catch((err, res) => {
          // setScreen(1);
          Swal.fire({
            //position: "top",
            icon: "error",
            title: "Please check email and password",
            // text: res.response.data["User Message"],
            showConfirmButton: true,
            timer: 3000,
            customClass: {
              container: styles1.swalCustomZindex,
              popup: styles1.swalCustomZindex,
            },
          });
          // setScreen(2);
        });
    }

    // dispatch(loginSuccess("4579438"));
    // router.push("/");
    // setScreen(1);
  };

  const [loading1, setLoading1] = useState(false);
  const handleMobileSubmit = (e) => {
    e.preventDefault();
    if (mobile === "") {
      Swal.fire({
        icon: "error",
        title: "Enter mobile number!",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: styles1.swalCustomZindex,
          popup: styles1.swalCustomZindex,
        },
      });
    } else {
      setLoading1(true);
      axios
        .post(
          `${process.env.NEXT_PUBLIC_BACKEND_GENERIC_URL}/skilling/send-otp`,
          {
            mobile: mobile,
            // mobnum: creds2.mobile,
            // password: creds.password,
          }
        )
        .then((res) => {
          console.log(res.data, "response11");
          // dispatch(loginSuccess(res.data));
          // saveData("user", res.data.user);
          // sessionStorage.setItem("candid", res.data.user.registration_id);

          // handleClose();
          Swal.fire({
            //position: "top",
            icon: "success",
            title: "OTP sent successfully!",
            // text: res.response.data["User Message"],
            showConfirmButton: true,
            timer: 3000,
            customClass: {
              container: styles1.swalCustomZindex,
              popup: styles1.swalCustomZindex,
            },
          });
          // router.push("/");
          setLoading1(false);

          setScreen(3);
        })
        .catch((err, res) => {
          // setScreen(1);
          const msg =
            err.response.data["Developer Message"] === "Phone Not Registered"
              ? "Looks like you’re not registered, Please register and come back!"
              : err.response.data["Developer Message"]
              ? err.response.data["Developer Message"]
              : "OTP not sent due to some network";
          Swal.fire({
            //position: "top",
            icon: "error",
            title: msg,
            // text: res.response.data["User Message"],
            showConfirmButton: true,
            timer: 3000,
            customClass: {
              container: styles1.swalCustomZindex,
              popup: styles1.swalCustomZindex,
            },
          });
          setLoading1(false);

          // setScreen(2);
        });
    }
  };

  const handleOTPSubmit = (e) => {
    e.preventDefault();
    if (otp === "") {
      Swal.fire({
        icon: "error",
        title: "Please enter OTP!",
        showConfirmButton: false,
        timer: 7000,
        customClass: {
          container: styles1.swalCustomZindex,
          popup: styles1.swalCustomZindex,
        },
      });
    } else {
      axios
        .post(
          `${process.env.NEXT_PUBLIC_BACKEND_GENERIC_URL}/skilling/verify-otp`,
          {
            mobile: mobile,
            otp: otp,
          }
        )
        .then((res) => {
          console.log(res.data, "response11");
          dispatch(loginSuccess(res.data));
          saveData("user", res.data.user);
          sessionStorage.setItem("candid", res.data.user.registration_id);
          // setScreen(1);
          // handleClose();
          Swal.fire({
            //position: "top",
            icon: "success",
            title: "Login successful!",
            // text: res.response.data["User Message"],
            showConfirmButton: true,
            timer: 3000,
            customClass: {
              popup: styles1.mypopup,
              title: styles1.mytitle,
              icon: styles1.myicon,
              container: styles1.swalCustomZindex,
              // popup: styles1.swalCustomZindex,
              confirmButton: styles1.button11,
            },
          }).then(() => {
            window.location.reload();
          });
          // router.push("/");
        })
        .catch((err, res) => {
          // setScreen(1);
          const msg =
            err.response.data.message == "Invalid OTP or OTP already used"
              ? "OTP entered is incorrect please check and input again"
              : "OTP entered is incorrect please check and input again";
          Swal.fire({
            //position: "top",
            icon: "error",
            // title: err.reponse?.data?.message
            //   ? err.reponse.data["message"]
            //   : "Please try again later",
            text: msg,
            showConfirmButton: true,
            timer: 3000,
            customClass: {
              container: styles1.swalCustomZindex,
              popup: styles1.swalCustomZindex,
            },
          });
          // setScreen(2);
        });
    }
    // setScreen(1);
  };

  const fetcheducation = () => {
    axios
      .get(`https://samarth.uat.api.globalsapio.com/generic/qualification`)
      .then((res) => {
        Seteducation(res.data);
        // setNewDDEducation(res.data.map((ele) => ele.value));
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    fetcheducation();
  }, []);

  //.......................Handle Registration.....................//
  const [creds2, setCreds2] = useState({
    name: "",
    username: "",
    mobile: "",
    qualification: "",
    password: "",
  });

  const handleChange2 = (e) => {
    if (e.target.name == "mobile") {
      const regex = /^\d{0,10}$/;

      if (regex.test(e.target.value)) {
        setCreds2({ ...creds2, [e.target.name]: e.target.value });
        // setError("");
      }
    } else {
      console.log(e.target.name, e.target.value);
      setCreds2({ ...creds2, [e.target.name]: e.target.value });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(creds2);
    // router.push("/login");
    if (
      creds2.name == "" ||
      creds2.username == "" ||
      creds2.mobile == "" ||
      creds2.password == "" ||
      creds2.qualification == ""
    ) {
      Swal.fire({
        //position: "top",
        icon: "error",
        title: `Please check all fields`,
        // text: res.response.data["User Message"],
        showConfirmButton: true,
        timer: 3000,
        customClass: {
          container: styles1.swalCustomZindex,
          popup: styles1.swalCustomZindex,
        },
      });
    } else {
      axios
        .post(
          `${process.env.NEXT_PUBLIC_BACKEND_GENERIC_URL}/skilling/${id}/auth/register`,
          {
            username: creds2.name,
            email: creds2.username,
            mobnum: creds2.mobile,
            password: creds2.password,
            qualification: creds2.qualification?.value,
          }
        )
        .then((res) => {
          console.log(res.data);
          setReg(false);

          Swal.fire({
            //position: "top",
            icon: "success",
            title: "Registration successful!",
            // text: res.response.data["User Message"],
            showConfirmButton: true,
            timer: 3000,
            customClass: {
              container: styles1.swalCustomZindex,
              popup: styles1.swalCustomZindex,
            },
          });
        })
        .catch((err, res) => {
          // setScreen(1);
          // setReg(false);
          Swal.fire({
            //position: "top",
            icon: "error",
            title: err.response.data["email"]
              ? err.response.data["email"][0]
              : err.response.data["mobnum"]
              ? err.response.data["mobnum"][0]
              : err.response.data["password"]
              ? err.response.data["password"][0]
              : "Please try again later",
            // text: res.response.data["User Message"],
            showConfirmButton: true,
            timer: 3000,
            customClass: {
              container: styles1.swalCustomZindex,
              popup: styles1.swalCustomZindex,
            },
          });
          // setReg(true);
          // setScreen(1);
        });
    }
  };

  return (
    <div className={styles.container}>
      {/* <div className={styles.imageContainer}>
        <Image src={image} alt="Login Image" priority={true} />
      </div> */}
      {!reg ? (
        <div className={styles.formContainer}>
          <Image
            src={details?.org_logo}
            alt="Login Image"
            height={200}
            width={200}
            priority={true}
          />

          <h4 className="my-4">Login</h4>
          {/* {screen !== 2 && (
          <div className={styles.toggleContainer}>
            <button
              type="button"
              onClick={() => {
                setLoginMode("password");
                setScreen(1);
              }}
              className={`${styles.buttonLeft} ${
                loginMode === "password" ? styles.active : ""
              }`}
            >
              Password
            </button>
            <button
              type="button"
              onClick={() => setLoginMode("otp")}
              className={`${styles.buttonRight} ${
                loginMode === "otp" ? styles.active : ""
              }`}
            >
              OTP
            </button>
          </div>
        )} */}
          {loginMode == "password" ? (
            <form onSubmit={handlePasswordSubmit} method="POST">
              <div className={styles.formGroup}>
                <label htmlFor="username">Email:</label>
                <input
                  type="email"
                  id="username"
                  name="username"
                  value={creds.username}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={creds.password}
                  onChange={handleChange}
                />
              </div>

              <div className={`${styles.buttonContainer} mt-6`}>
                <button type="submit" className={styles.button}>
                  {loginMode == "password" ? "Login" : "Send OTP"}
                </button>
              </div>
            </form>
          ) : (
            <div>
              {screen == 1 ? (
                <form onSubmit={handleMobileSubmit} method="POST">
                  <div className={styles.formGroup}>
                    <label htmlFor="otp">Mobile:</label>
                    <input
                      type="tel"
                      id="mobile"
                      name="mobile"
                      value={mobile}
                      pattern="[0-9]{10}"
                      onChange={
                        (e) => handleChangeMobile(e)
                        // setMobile(e.target.value)
                      }
                    />
                  </div>
                  <div className={`${styles.buttonContainer} mt-6`}>
                    <button
                      type={!loading1 && "submit"}
                      className={styles.button}
                      disabled={loading1}
                      style={{ cursor: loading1 ? "no-drop" : "pointer" }}
                    >
                      Send OTP
                    </button>
                  </div>
                </form>
              ) : screen == 3 ? (
                <form onSubmit={handleOTPSubmit} method="POST">
                  <div className={styles.formGroup}>
                    <label htmlFor="otp">OTP:</label>
                    <input
                      type="text"
                      id="otp"
                      name="otp"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                  </div>
                  <div className={`${styles.buttonContainer} mt-6`}>
                    <button type="submit" className={styles.button}>
                      Login
                    </button>
                  </div>
                </form>
              ) : (
                ""
              )}
            </div>
          )}

          <div className="my-3">
            {screen == 2 ? (
              <div className={styles.flex_div1}>
                <div className={styles.resend} onClick={handleMobileSubmit}>
                  <p className={styles.redirectText}>
                    Didn&apos;t receive OTP? Resend
                  </p>
                </div>
                <div>
                  <p>or</p>
                </div>
                <div
                  className={styles.resend}
                  onClick={() => {
                    setScreen(1);
                    setLoginMode("password");
                  }}
                >
                  <p className={styles.redirectText}>Login with password</p>
                </div>
              </div>
            ) : (
              <div className={styles.flexCol}>
                {loginMode == "password" ? (
                  <div
                    onClick={() => setLoginMode("otp")}
                    className={styles.cursorPointer}
                  >
                    <p className={styles.redirectText}>Login with otp</p>
                  </div>
                ) : (
                  <div
                    onClick={() => setLoginMode("password")}
                    className={styles.cursorPointer}
                  >
                    <p className={styles.redirectText}>Login with password</p>
                  </div>
                )}
                {/* <Link href="/register"> */}
                <div
                  onClick={() => setReg(true)}
                  className={styles.cursorPointer}
                >
                  <p className={styles.redirectText}>
                    Don&apos;t have an account? Register now
                  </p>
                </div>
                {/* </Link> */}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.formContainer}>
          <Image
            src={details?.org_logo}
            alt="Login Image"
            height={200}
            width={200}
            priority={true}
          />
          <h4 className="my-2">Register</h4>
          <form onSubmit={handleSubmit} method="post">
            <div className={styles.formGroup}>
              <label htmlFor="username">Username:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={creds2.name}
                onChange={handleChange2}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="username">Email:</label>
              <input
                type="email"
                id="username"
                name="username"
                value={creds2.username}
                onChange={handleChange2}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="mobile">Mobile Number:</label>
              <input
                type="tel"
                id="mobile"
                name="mobile"
                value={creds2.mobile}
                onChange={handleChange2}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="mobile">Qualification:</label>
              <div style={{ height: "40px" }}>
                <Select
                  styles={stylesDD}
                  // type="select"
                  // isClearable
                  // isMulti
                  id="qualification"
                  name="qualification"
                  options={education}
                  placeholder="Select qualification"
                  value={creds2.qualification}
                  // onChange={handleChange2}
                  onChange={(e) => {
                    setCreds2({ ...creds2, qualification: e });
                  }}
                />
              </div>
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                value={creds2.password}
                onChange={handleChange2}
              />
            </div>

            <div className={styles.buttonContainer}>
              <button type="submit" className={styles.button}>
                Submit
              </button>
            </div>
          </form>

          <div className="m-3">
            {/* <Link href="/login"> */}
            <div onClick={() => setReg(false)} className={styles.cursorPointer}>
              <p className={styles.redirectText}>
                Already have an account? Login
              </p>
            </div>
            {/* </Link> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
