// components/MyModal.js
import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LoginPage from "../Login/LoginPage";
import Register from "../Login/Register";
import { loadData } from "@/utils/localStorage";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const MyModal = ({ open, handleClose, handleOpen }) => {
  const [data, setData] = useState(0);
  const [user, setUser] = useState(null);
  useEffect(() => {
    setUser(loadData("user"));
  }, [data]);
  useEffect(() => {
    data == 1 && handleClose();
  }, [data]);
  console.log(data, "dt11");
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        {/* {screen==1?<LoginPage />:<Register />} */}
        <LoginPage handleClose={handleClose} />
      </Box>
    </Modal>
  );
};

export default MyModal;
