import Image from "next/image";
import Link from "next/link";

import logo from "../../public/images/logo/logo.png";

import FooterData from "../../data/footer.json";
import SingleFooter from "./FooterProps/SingleFooter";
import CopyRight from "./CopyRight";
import { useSelector } from "react-redux";

const FooterThree = () => {
  const website = useSelector((state) => state.WebsiteReducer.web_details);
  const { details } = useSelector((state) => state.CompanyReducer);

  return (
    <>
      {website !== null && (
        <footer className="rbt-footer footer-style-1">
          <div className="footer-top">
            <div className="container">
              {FooterData &&
                FooterData.footerOne.map((footer, index) => (
                  <div className="row row--15 mt_dec--30" key={index}>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30">
                      <div className="footer-widget">
                        <div className="logo">
                          <Link href="/">
                            <Image
                              src={
                                details?.org_logo !== null
                                  ? details?.org_logo
                                  : ""
                              }
                              width={152}
                              height={70}
                              alt={details?.org_name}
                            />
                          </Link>
                        </div>

                        <p className="description mt--20">
                          {footer.description}
                        </p>

                        <div className="contact-btn mt--30">
                          <Link
                            className="rbt-btn hover-icon-reverse btn-border-gradient radius-round"
                            href="tel:+918657933489"
                          >
                            <div className="icon-reverse-wrapper">
                              <span className="btn-text">Ask me</span>
                              <span className="btn-icon">
                                <i className="feather-arrow-right"></i>
                              </span>
                              <span className="btn-icon">
                                <i className="feather-arrow-right"></i>
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* 
                  <SingleFooter
                    classOne="offset-lg-1 col-lg-2 col-md-6 col-sm-6 col-12 mt--30"
                    title="Useful Links"
                    footerType={footer.usefulLinks}
                  /> */}

                    {/* <SingleFooter
                    classOne="col-lg-2 col-md-6 col-sm-6 col-12 mt--30"
                    title="Our Company"
                    footerType={footer.ourCompany}
                  /> */}

                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30">
                      <div className="footer-widget">
                        <h5 className="ft-title">Contact us</h5>
                        <ul className="ft-link">
                          <li>
                            <span>Phone:</span>{" "}
                            {website !== null && (
                              <Link href="#">{`+91-${website?.contact_phone}`}</Link>
                            )}
                          </li>
                          <li>
                            <span>E-mail:</span>{" "}
                            <Link href="#">{website?.contact_email}</Link>
                          </li>
                          <li>
                            <span>Location:</span> {website?.contact_address}
                          </li>
                          {/* <li>
                            <span>State:</span> {footer.address}
                          </li>
                          <li>
                            <span>District:</span> {footer.address}
                          </li> */}
                        </ul>
                        <ul className="social-icon social-default icon-naked justify-content-start mt--20">
                          {/* {footer.socialLink.map((value, innerIndex) => (
                          <li key={innerIndex}>
                            <Link href={value.link}>
                              <i className={value.icon}></i>
                            </Link>
                          </li>
                        ))} */}
                          {/* <li>
                            <Link
                              href={
                                website?.sm_facebook ? website?.sm_facebook : ""
                              }
                            >
                              <i className="feather-facebook"></i>
                            </Link>
                          </li>
                          <li>
                            <Link
                              href={
                                website?.sm_twitter ? website?.sm_twitter : ""
                              }
                            >
                              <i className="feather-twitter"></i>
                            </Link>
                          </li> */}
                          <li>
                            <Link
                              target="_blank"
                              href="https://www.linkedin.com/in/sikho-kamana-4a30792b2?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3By9vxacQsREWIUP5buzprdg%3D%3D"
                            >
                              <i className="feather-linkedin"></i>
                            </Link>
                          </li>
                          <li>
                            <Link
                              href="https://www.instagram.com/sikhokamana_/"
                              target="_blank"
                            >
                              <i className="feather-instagram"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </footer>
      )}
      <CopyRight />
    </>
  );
};

export default FooterThree;
